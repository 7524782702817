import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Name from "../components/name"
import style from "./index.module.scss"

const IndexPage = ({ data }) => (
  <div className={style.wrapper}>
    <SEO />
    <Link className={style.link} to="/projects/">
      <Name className={style.name} />
    </Link>
  </div>
)

export default IndexPage
